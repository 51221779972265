@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.day {
  $breakpoint: 750px;

  &__row {
    display: flex;
    flex-wrap: wrap;
    border: 0;
    margin: 0;
    margin-bottom: spacing.$m;
    padding: spacing.$m;
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;
  }

  &__legend-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__legend,
  &__checkbox {
    width: 100px;
    margin-right: spacing.$m;
  }

  &__timeslot-list {
    margin-top: spacing.$m;
    @media (min-width: $breakpoint) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__timeslot-list-item {
    display: flex;
    align-items: center;
    gap: spacing.$l;

    &:not(:last-child) {
      margin-bottom: spacing.$m;
    }
  }

  &__timeslot {
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint) {
      margin-top: 0;
    }
  }

  &__divider {
    margin: 0 spacing.$s;
  }

  &__extra-hours-add,
  &__extra-hours-remove,
  &__extra-hours-remove-mobile {
    margin-right: auto;
    > * + * {
      margin-top: spacing.$m;
    }
  }

  &__extra-hours-remove {
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__extra-hours-remove-mobile {
    display: none;
    @media (max-width: $breakpoint) {
      display: flex;
    }
  }

  &__buttons {
    > * + * {
      margin-left: spacing.$xs;
    }
  }
}
